<!--
File: TreatmentMatrixEditForm.vue
Description: form for adding/editing a single row of the Treatment Matrix.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.items.length"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <EditFields ref="dynamicForm" v-model="treatmentMatrixItem" :fields="fields" :validations="validations" />
    </template>
    
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal, EditFields } from '@/pages/Components'
import permissions from "@/mixins/permissionsMixin"
import { savedMessage } from '@/mixins/messagesMixin'

export default {
  name: 'treatment-matrix-edit-form',
  mixins: [permissions],

  data() {
    return {
      formName: 'TreatmentMatrixEditForm',
      eligible: false,

      treatmentMatrixItem: {},
      isLoading: false,
      initialFormState: null,
    }
  },

  props: {
    itemId: null
  },

  components: {
    Modal,
    EditFields,
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed()
    if (!this.eligible) {
      this.$emit('close')
      return
    };

    if (this.itemId) { // Load data on existing item
      this.treatmentMatrixItem = await this.loadItem(this.itemId);
    };

    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$refs.dynamicForm.validateForm();
    });
  },

  methods: {
    ...mapActions({
      loadItem: 'ReferenceData/LOAD_TREATMENT_MATRIX_BY_ID',
      addItem: 'ReferenceData/ADD_NEW_TREATMENT_MATRIX',
      editItem: 'ReferenceData/UPDATE_TREATMENT_MATRIX',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async validate() {
      const isValid = await this.$refs.dynamicForm.validateForm();
      if (!isValid) return;

      const theItem = this.getCurrentState;
      let errDesc = '';
      let newId;
      const action = !this.itemId ? this.addItem : this.editItem;
      const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
      try {
        const res = await action(payload);
        newId = res?.treatment_matrix_id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error');
      }
      this.$nextTick(() => this.$validator.reset());
      this.$emit('close');
      await savedMessage(errDesc, this.$t('route.treatment_matrix'), '');
      this.highlightRow(newId);
    }
  },

  computed: {
    getCurrentState() {
      return {
        fk_treatment_type: Number(this.treatmentMatrixItem?.fk_treatment_type) || 0,
        iri_from: Number(this.treatmentMatrixItem?.iri_from) || 0,
        iri_to: Number(this.treatmentMatrixItem?.iri_to) || 0,
        rutting_from: Number(this.treatmentMatrixItem?.rutting_from) || 0,
        rutting_to: Number(this.treatmentMatrixItem?.rutting_to) || 0,
        cracking_from: Number(this.treatmentMatrixItem?.cracking_from) || 0,
        cracking_to: Number(this.treatmentMatrixItem?.cracking_to) || 0,
        potholes_from: Number(this.treatmentMatrixItem?.potholes_from) || 0,
        potholes_to: Number(this.treatmentMatrixItem?.potholes_to) || 0,
        aadt_from: Number(this.treatmentMatrixItem?.aadt_from) || 0,
        aadt_to: Number(this.treatmentMatrixItem?.aadt_to) || 0,
      };
    },

    screenTitle() {
      return this.itemId ? this.$t('screen_titles.treatment_matrix_upd') : this.$t('screen_titles.treatment_matrix_add')
    },

    fields() {
      return [
        { name: 'fk_treatment_type', type: 'custom', component: 'TreatmentsDropdown', class: 'md-layout-item md-size-100' },
        { name: 'iri_from', label: `${this.$t('condition.iri')}, ${this.$t('label.value_from')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'iri_to', label: `${this.$t('condition.iri')}, ${this.$t('label.value_to')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'rutting_from', label: `${this.$t('condition.rutting')}, ${this.$t('label.value_from')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'rutting_to', label: `${this.$t('condition.rutting')}, ${this.$t('label.value_to')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'cracking_from', label: `${this.$t('condition.cracking')}, ${this.$t('label.value_from')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'cracking_to', label: `${this.$t('condition.cracking')}, ${this.$t('label.value_to')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'potholes_from', label: `${this.$t('label.potholes')}, ${this.$t('label.value_from')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'potholes_to', label: `${this.$t('label.potholes')}, ${this.$t('label.value_to')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'aadt_from', label: `${this.$t('condition.aadt')}, ${this.$t('label.value_from')}`, type: 'number', class: 'md-layout-item md-size-50' },
        { name: 'aadt_to', label: `${this.$t('condition.aadt')}, ${this.$t('label.value_to')}`, type: 'number', class: 'md-layout-item md-size-50' },
      ];
    },

    validations() {
      return {
        fk_treatment_type: { required: true, numeric: true, min_value: 1 },
        iri_from: { required: true, numeric: true, min_value: 0 },
        iri_to: { required: true, numeric: true, min_value: 0 },
        rutting_from: { required: true, numeric: true, min_value: 0 },
        rutting_to: { required: true, numeric: true, min_value: 0 },
        cracking_from: { required: true, numeric: true, min_value: 0 },
        cracking_to: { required: true, numeric: true, min_value: 0 },
        potholes_from: { required: true, numeric: true, min_value: 0 },
        potholes_to: { required: true, numeric: true, min_value: 0 },
        aadt_from: { required: true, numeric: true, min_value: 0 },
        aadt_to: { required: true, numeric: true, min_value: 0 },
      };
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false;

      return this.treatmentMatrixItem?.fk_treatment_type !== this.initialFormState.fk_treatment_type ||
        this.treatmentMatrixItem?.iri_from !== this.initialFormState.iri_from ||
        this.treatmentMatrixItem?.iri_to !== this.initialFormState.iri_to ||
        this.treatmentMatrixItem?.rutting_from !== this.initialFormState.rutting_from ||
        this.treatmentMatrixItem?.rutting_to !== this.initialFormState.rutting_to ||
        this.treatmentMatrixItem?.cracking_from !== this.initialFormState.cracking_from ||
        this.treatmentMatrixItem?.cracking_to !== this.initialFormState.cracking_to ||
        this.treatmentMatrixItem?.potholes_from !== this.initialFormState.potholes_from ||
        this.treatmentMatrixItem?.potholes_to !== this.initialFormState.potholes_to ||
        this.treatmentMatrixItem?.aadt_from !== this.initialFormState.aadt_from ||
        this.treatmentMatrixItem?.aadt_to !== this.initialFormState.aadt_to;
    }
  }
};
</script>
<style lang='scss' scoped>
.modal-container {
  max-width: 300px;
}
</style>