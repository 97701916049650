<!--
File: TreatmentMatrixList.vue
Description: shows the Treatment Matrix as the list.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination" :from="from" :to="to" :total="total"
    :btnAddAllowed="isBtnAllowed('AddButton')" @add-item="viewItem()">

    <template #header-filters>
      <BaseDropdown class="md-layout-item md-size-20" v-model="selectedTreatment" :label="$t('treatment.treatment_key')"
        :items="treatmentKeys" />
    </template>

    <template #table-rows="{ item }">
      <base-table :items="queriedData" :headers="headers" :idFieldName="idFieldName" :sort="currentSort"
        :sortOrder="currentSortOrder">

        <template #table-actions="{ item }">
          <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
            @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.road_description)" />
        </template>

      </base-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :itemId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { BaseTable, BaseDataTable, TableActions, BaseDropdown } from '@/pages/Components'
import BaseTableMixin from '@/mixins/BaseTableMixin'
import EditForm from './TreatmentMatrixEditForm.vue'

export default {
  name: 'TreatmentMatrixList',
  mixins: [BaseTableMixin],

  data() {
    return {
      formName: 'TreatmentMatrixList',
      idFieldName: 'id',
      currentId: null,

      selectedTreatment: null,
      currentSort: 'description',

      historyMapping: {
        selectedTreatment: null,
        currentSort: 'description',
        currentSortOrder: 'asc',
        pagination: this.pagination,
        searchQuery: ''
      }
    }
  },

  components: {
    BaseTable,
    BaseDataTable,
    TableActions,
    BaseDropdown,
    EditForm
  },

  methods: {
    ...mapActions({
      clearList: 'ReferenceData/CLEAR_TREATMENT_MATRIX_LIST',  // Called from the BaseTableMixin's mount()
      loadList: 'ReferenceData/LOAD_TREATMENT_MATRIX_LIST',
    }),

    viewItem(id) {
      this.currentId = id;
      this.showDetailsDlg = true;
    },
  },

  computed: {
    ...mapState({
      //treatmentMatrix: (state) => state.ReferenceData.treatment_matrix_list,
    }),
    ...mapGetters('ReferenceData', ['getTreatmentMatrixUniqueValues', 'matrixCombined']),

    headers() {
      return [
        { label: "stdCols.key", value: "key", sortable: true },
        { label: "stdCols.description", value: "description", sortable: true },
        { label: "condition.aadt", value: "aadt", sortable: true },
        { label: "condition.iri", value: "iri", sortable: true },
        { label: "condition.rutting", value: "rutting", sortable: true },
        { label: "condition.cracking", value: "cracking", sortable: true },
        { label: "label.potholes", value: "potholes", sortable: true },
        { label: "label.expected_outcome", value: "expected_outcome", sortable: true },
      ]
    },

    tableData() {
      return this.matrixCombined(this.selectedTreatment);
    },

    treatmentKeys() {
      return this.getTreatmentMatrixUniqueValues('key', 'fk_treatment_type');
    },
  },

  watch: {
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>